import React from 'react';
import { graphql } from 'gatsby';
import { Container } from '../components/container/container';
import { LayoutContent } from '../components/layout/layout-content';
import { BlockContent } from '../components/block-content/block-content';
import { AssetImg } from '../components/asset/asset-img';
import { SEO } from '../components/seo';
import styles from './blog-post.module.scss';
import AssetContext from '../contexts/AssetContext';

const BlogPost = ({ data }) => {
  const {
    post: { title, publishedAt, mainImage = {}, body },
    assets = [],
  } = data;

  const assetContext = { assets };

  return (
    <AssetContext.Provider value={{ assetContext }}>
      <LayoutContent>
        <SEO title={title} />

        <Container variant="extra-thin" className={styles.header}>
          <p className={styles.blogDate}>{publishedAt}</p>
          <h1>{title}</h1>
        </Container>

        <Container variant="thin" className={styles.mainImageContainer}>
          {mainImage.asset && (
            <div className={styles.mainImageWrapper}>
              <AssetImg asset={mainImage.asset} alt={mainImage.alt} className={styles.mainImage} />
            </div>
          )}
        </Container>
        <Container variant="extra-thin">
          <BlockContent blocks={body} />
        </Container>
      </LayoutContent>
    </AssetContext.Provider>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($id: String!, $imageAssetIds: [String]) {
    post: sanityPost(id: { eq: $id }) {
      title
      categories {
        title
        language
        id: _id
      }
      slug {
        current
      }
      publishedAt: publishedAt(formatString: "MMM D, YYYY")
      mainImage {
        asset {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(
                maxWidth: 1100
                maxHeight: 723
                srcSetBreakpoints: [340, 768, 1100]
                traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
              ) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            extension
          }
          extension
        }
        alt
      }
      body: _rawBody
    }
    assets: allSanityImageAsset(filter: { id: { in: $imageAssetIds } }) {
      nodes {
        id: _id
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1780
              srcSetBreakpoints: [340, 768, 1780]
              traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          extension
        }
      }
    }
  }
`;
